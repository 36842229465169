const projects = [
    {
        id: 1,
        name1: 'My',
        name2: 'Portfolio',
        modalTitle: 'My Portfolio Site',
        modalDescription: 'This is the portfolio of me built using React js. You are already on this site.',
        link: 'https://www.kiranveer.tech',
        className: 'project__link portfolio'
    },
    {
        id: 2,
        name1: 'Me',
        name2: 'Tube',
        modalTitle: 'MeTube',
        modalDescription: 'Clone web application of YouTube developed using react js and Rapid API.',
        link: 'https://keyrunmetube.netlify.app/',
        className: 'project__link metube'
    },
    {
        id: 3,
        name1: 'E-Instant Game',
        name2: 'Cashing Through the Snow',
        modalTitle: 'Instant Game for Norwey Lottery',
        modalDescription: 'This is the E-Instant Game developed using PIXI, Node and GSAP.',
        link: '',
        className: 'project__link CTTS'
    },
    {
        id: 4,
        name1: 'E-Instant Game',
        name2: 'First Class Cash',
        modalTitle: 'Instant Game for Canadian Lottery',
        modalDescription: 'This is the E-Instant Game developed using PIXI, Node and GSAP.',
        link: '',
        className: 'project__link FCC'
    },
    {
        id: 5,
        name1: 'E-Instant Game',
        name2: 'Maximum Cash',
        modalTitle: 'Instant Game for Multiple Lotteries',
        modalDescription: 'This is the E-Instant Game developed using PIXI, Node and GSAP.',
        link: '',
        className: 'project__link MAXCASH'
    },
    {
        id: 6,
        name1: 'E-Instant Game',
        name2: 'The Game of Life',
        modalTitle: 'Instant Game for Multiple Lotteries',
        modalDescription: 'This is the E-Instant Game developed using PIXI, Node and GSAP.',
        link: '',
        className: 'project__link GOL'
    },
    {
        id: 7,
        name1: 'E-Instant Game',
        name2: 'Boo-tiful Bucks',
        modalTitle: 'Instant Game for Multiple Lotteries',
        modalDescription: 'This is the E-Instant Game developed using PIXI, Node and GSAP.',
        link: '',
        className: 'project__link BB'
    },
    {
        id: 8,
        name1: 'E-Instant Game',
        name2: '3asy Money',
        modalTitle: 'Instant Game for Multiple Lotteries',
        modalDescription: 'This is the E-Instant Game developed using PIXI, Node and GSAP.',
        link: '',
        className: 'project__link EASYM'
    },
    {
        id: 10,
        name1: 'Tejas',
        name2: 'Suppliers',
        modalTitle: 'Tejas Suppliers',
        modalDescription: 'Successfully built Website of Client having Sand mining company',
        link: 'https://tejassuppliers.netlify.app',
        className: 'project__link tejassupliers'
    },
    {
        id: 11,
        name1: 'KLE',
        name2: 'E-Xchange',
        modalTitle: 'KLE E-Xchange',
        modalDescription: 'Website to help students exchange their notes and study materials.',
        link: 'https://socialinnh001.wixsite.com/e-xchange',
        className: 'project__link eXchange'
    },
    {
        id: 12,
        name1: 'TodoList',
        name2: 'App',
        modalTitle: 'TodoList App',
        modalDescription: 'Built a Todo list app using Nodejs, MongoDB and Ejs',
        link: 'https://todolistapp-eq9r.onrender.com',
        className: 'project__link todoApp'
    }
]
export default projects